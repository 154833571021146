import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Because you demanded it…today starts`}</em></p>
    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`“Hortman”`}</em></strong></p>
    <p>{`45:00 AMRAP of:`}</p>
    <p>{`800M Run`}</p>
    <p>{`80 Squats`}</p>
    <p>{`8 Muscle Ups`}</p>
    <p><strong parentName="p">{`*`}{`There will be no CrossFit Kids today.`}</strong></p>
    <p><strong parentName="p">{`*`}{`Judging for 17.4 will take place at The Ville today from
10:00am-2:00pm. `}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      